<template>
  <div class="IssueCrm7">
    <div class="form-group-container">

    <p for="periodicity" class="guarantee-text">{{IssueCrm7textos.formulario.issuecrm7.periodicidade}}</p>

    <select id="periodicity" class="select-Class">
      <option value="" disabled selected>Selecione</option>
      <option value="{{IssueCrm7textos.formulario.issuecrm7.selecionaPeriodo.unico}}">{{IssueCrm7textos.formulario.issuecrm7.selecionaPeriodo.unico}}</option>
      <option value="{{IssueCrm7textos.formulario.issuecrm7.selecionaPeriodo.mensal}}">{{IssueCrm7textos.formulario.issuecrm7.selecionaPeriodo.mensal}}</option>
     
    </select>
    <br style="height: 120px;">

    <div class="donation-amounts">
      <button class="amount-button" @click="setDonation(40)">{{IssueCrm7textos.formulario.issuecrm7.valores.quarenta}}</button>
      <button class="amount-button" @click="setDonation(100)">{{IssueCrm7textos.formulario.issuecrm7.valores.cem}}</button>
      <button class="amount-button" @click="setDonation(150)">{{IssueCrm7textos.formulario.issuecrm7.valores.cento_e_cinquenta}}</button>
      <button class="amount-button" @click="setDonation(999)">{{IssueCrm7textos.formulario.issuecrm7.valores.outro}}</button>
    </div>
    <!-- Exibe a mensagem com base no valor da doação -->
    <div v-if="currentStep === 40">
        <p v-html="IssueCrm7textos.formulario.issuecrm7.impacto.quarenta.replace(/\n/g, '<br>')" class="guarantee-text"></p>
    </div>
    <div v-else-if="currentStep === 100">
      <p v-html="IssueCrm7textos.formulario.issuecrm7.impacto.cem.replace(/\n/g, '<br>')" class="guarantee-text"></p>
    </div>
    <div v-else-if="currentStep === 150">
      <p v-html="IssueCrm7textos.formulario.issuecrm7.impacto.cento_e_cinquenta.replace(/\n/g, '<br>')" class="guarantee-text"></p> 
    </div>

    <!-- Div que contém o input quando "Outro valor" é selecionado -->
    <div v-else-if="currentStep === 999">
      <p class="guarantee-text">
       
        <br>
        <label for="otherAmount" v-html="IssueCrm7textos.formulario.issuecrm7.impacto.outro.replace(/\n/g, '<br>')" class="guarantee-text"></label> 
      </p>
      <input  type="number"  id="otherAmount"  v-model="FormData.otherAmount"  placeholder="Digite outro valor"   @input="ValorotherAmount"  @blur="ValorotherAmount" />
     
    </div>
     <span v-if="FormErrors.otherAmount" class="error-message">{{ FormErrors.otherAmount }} </span>
    </div>
  </div>
</template>
<script>

import IssueCrm7dados from '@/assets/dados.json';
export default {
  name: "IssueCrm7",
  version: '1.0.0',
  createdDate: '2024-10-12',
  lastUpdated: '2024-10-13',

  
  data() {
    return {
      showOtherAmountInput: false, // Controle de visibilidade do input
      otherAmount: null, // Valor do input
      IssueCrm7textos: IssueCrm7dados, // Assumindo que você carrega o JSON como um objeto de dados chamado 'dados',
      impactMessages: [],

      FormData:{
        otherAmount:""
      },
      FormErrors:{
        otherAmount:""
      }

    };

   
    
  },

  
  methods: {

    ValorotherAmount(){

      console.log('Mensagem de debug');

      const valorMinimo = 15; // valor definido como minimo
      if (this.FormData.otherAmount && this.FormData.otherAmount < valorMinimo) {
      this.FormErrors.otherAmount = `O valor deve ser maior ou igual a ${valorMinimo}.`;
    } else {
      this.FormErrors.otherAmount = ""; // Limpa a mensagem de erro se o valor for válido
    }

    }





  },
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    setDonation: {
      type: Function,
      required: true

    }
  },

}
</script>
<style scoped>

/* Importa a fonte Roboto do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

/* Reset CSS para remover margens, preenchimentos e bordas */
label, h2, h1, p, .error-message {
  font-family: 'Roboto', sans-serif; /* Fonte desejada */
  font-weight: 300; /* Peso da fonte mais fino */
  margin-bottom: 5px; /* Espaçamento abaixo do rótulo */
}

label, p {
  font-size: 14px; /* Tamanho de fonte ajustado */
}

/* Ajusta a largura de elementos de entrada para telas maiores */
.select-Class {
  width: 120px; /* Define uma largura personalizada */
}

.donation-amounts {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  flex-wrap: wrap; /* Permite que os botões quebrem em uma nova linha em telas menores */
}

.amount-button {
  background-color: #36806D; /* Azul */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.amount-button:hover {
  background-color: #22c413; /* Azul mais escuro ao passar o mouse */
}

.amount-button.selected {
  background-color: #001e80; /* Azul ainda mais escuro quando pressionado */
}

/* Ajusta os campos de entrada para serem responsivos */
input,
select {
  width: 100%; /* Largura total para dispositivos móveis */
  max-width: 300px; /* Limita a largura máxima para evitar exageros em telas grandes */
  padding: 8px; /* Espaço interno */
  font-size: 12px; /* Tamanho de fonte para o texto dentro dos campos */
  border: 1px solid #ccc; /* Borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
  font-weight: 300; /* Peso da fonte mais leve */
}

/* Estilo para a mensagem de erro */
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

input:focus {
  border-color: #36806D; /* Cor de borda ao focar */
  outline: none; /* Remove o contorno padrão */
}

/* Media Queries para garantir que os elementos sejam responsivos em diferentes dispositivos */
@media screen and (max-width: 768px) {
 

  .select-Class {
    width: 80%; /* Ajusta a largura para dispositivos móveis */
  }

  label, p {
    font-size: 12px; /* Reduz o tamanho da fonte para dispositivos móveis */
  }
  .donation-amounts {
  display: flex;
  gap: 5px;
  margin: 5px 0;
  flex-wrap: wrap; /* Permite que os botões quebrem em uma nova linha em telas menores */
}
  
}

</style>
