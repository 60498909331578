<template>
  <div class="IssueCrm9">
    
    <h2>Escolha a forma de doação:</h2>
    <div class="form-group">
      <div for="cpf" class="form-div" >CPF  <span class="asterisco">*</span></div>
      <input type="text" id="cpf" v-model="FormData.cpf" placeholder="___.___.___-__" required class="campo-Class"/>
    </div>


    <div class="form-group">
      <div for="gender"  class="form-div" >Forma de Doação<span  class="asterisco">*</span></div>
      <select id="cardMethod"   @change="handleCardMethodChange" required  class="select-Class">
            <option value="" disabled selected>Selecione</option>
            <option value="1">Boleto</option>
            <option value="2">Cartão de Crédito</option>
            <option value="3">PIX</option>
            <option value="3">Transferência</option>
     </select>
    </div>
    <div  v-if="met === 1" >
      <div class="form-group">
      <div for="cardBandeira"  class="form-div" >Bandeira<span  class="asterisco">*</span></div>
      <select id="cardBandeira" v-model="FormData.Carbandeira"   required  class="select-bandeira-Class">
            <option value="" disabled selected>Selecione</option>
           
     </select>
    </div>
    <div class="form-group">
      <div for="Numbercard" class="form-div" >Número do Cartão  <span class="asterisco">*</span></div>
      <input type="text" id="Numbercard" v-model="FormData.numberCard"  class="campo-Numbercar-Class"/>
    </div>
    <div class="form-group">
      <div for="nameTitularidade" class="form-div" >Nome do Titular  <span class="asterisco">*</span></div>
      <input type="text" id="nameTitularidade" v-model="FormData.nameTitularidade"  class="campo-Str-Class"/>
    </div>
    <div class="form-group">
      <div for="valideCard" class="form-div" >Nome do Titular  <span class="asterisco">*</span></div>
      <input type="text" id="valideCar" v-model="FormData.valideCard" placeholder="MM/AA"  class="campo-Class"/>
    </div>
    </div>
    
  

</div>

</template>

<script>

export default {

  name: "IssueCrm9",
  version: '1.0.0',
  createdDate: '2024-10-21',
  lastUpdated: '',
  cardStep:0,
  data(){
    return{
        FormData:{
            cpf:'',
            cardMethod:'',
            CarBandeira:'',
            numberCard:'',
            nameTitularidade:'',
            valideCard:'',
        },
        met:''
      
    };
  },
  
  methods: {
    /* Esse metodo ao selecionar o evento com metodo de cobrança cartão de credito ele abri a div */
    handleCardMethodChange(event) {
    this.FormData.cardMethod = event.target.value  ;
    if (this.FormData.cardMethod  === "1" ){
      this.met = 1;
    }else{
      this.met = "";
    }
    
  }
}

};




</script>
<style scoped>
/* Importa a fonte Roboto do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

/* Container principal do formulário */
.form-group-container {
  display: flex;
  flex-wrap: wrap; /* Permite reorganizar os campos em telas menores */
  gap: 20px; /* Espaço entre os grupos de campos */
  max-width: 800px; /* Largura máxima do formulário */
  margin: 0 auto; /* Centraliza o formulário */
  padding: 10px; /* Espaçamento interno para uma melhor aparência */
}

.form-group {
  display: grid;
  grid-template-columns: auto 2fr; /* Define duas colunas */
  align-items: baseline; /* Alinha verticalmente ao centro */
  margin-left: 15px;
  gap: 15px; /* Espaço entre os itens */
  padding: 15px;
}

/* Estilo para os rótulos */
label, h2, .form-div {
  font-family: 'Roboto', sans-serif; /* Fonte desejada */
  font-weight: 300; /* Peso da fonte mais fino */
  margin-bottom: 5px; /* Espaçamento abaixo do rótulo */
}

.form-group, label {
  font-size: 12px; /* Tamanho de fonte ajustado */
}
.form-div{
  width: 150px;
}

h2 {
  font-size: 16px; /* Tamanho de fonte ajustado */
}

/* Estilo para os campos de entrada e seleção */
input,
select {
  width: 100%; /* Largura total para responsividade */
  padding: 8px; /* Espaço interno */
  font-size: 12px; /* Tamanho de fonte para o texto dentro dos campos */
  border: 1px solid #ccc; /* Borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
  font-weight: 300; /* Peso da fonte mais leve */
}

/* Estilo para garantir que os três campos fiquem na mesma linha */
.box-input {
  display: flex; /* Usa flexbox para alinhar os campos em linha */
  gap: 20px; /* Espaçamento entre os campos */
  flex-wrap: wrap; /* Permite que os campos reorganizem em telas menores */
  margin-bottom: 15px; /* Espaçamento na parte inferior */
}

.box-input .form-group {
  flex: 1; /* Permite que os campos se ajustem ao espaço disponível */
  min-width: 150px; /* Largura mínima para manter campos consistentes */
}

.box-input input, 
.box-input select {
  width: 100%; /* Garante que cada campo ocupe toda a largura disponível dentro do grupo */
  padding: 8px; /* Espaçamento interno */
  border: 1px solid #ccc; /* Borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
  font-size: 12px; /* Ajuste do tamanho de fonte */
}



input:focus,
select:focus {
  border-color: #36806D; /* Cor de borda ao focar */
  outline: none; /* Remove o contorno padrão */
}


.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

input:focus {
  border-color: #36806D; /* Cor de borda ao focar */
  outline: none; /* Remove o contorno padrão */
}

.asterisco {
  color: rgba(13, 155, 13, 0.884);
  font-weight: 700;
  padding: 5px;
}

/* Estilo para checkbox e label */
.checkbox-container {
  display: flex; /* Usando flexbox para alinhar o checkbox e o label */
  align-items: center; /* Alinha verticalmente ao centro */
  gap: 10px; /* Espaço entre o checkbox e o label */
  margin-bottom: 15px; /* Espaço abaixo do checkbox */
}

.checkbox-container label {
  font-size: 14px; /* Aumenta o tamanho da fonte do label */
}

/* Classes para campos de entrada personalizados */
.select-Class {
  width: 140px; /* Ajusta a largura para 100% */
}
.select-bandeira-Class {
  width: 255px; /* Ajusta a largura para 100% */
}
.campo-Class {
  width: 140px; /* Ajusta a largura para 100% */
}
.campo-Numbercar-Class {
  width: 255px; /* Ajusta a largura para 100% */
}

.campo-Str-Class {
  width: 255px; /* Ajusta a largura para 100% */
}


/* Ajustes para telas menores */
@media (max-width: 600px) {

  .form-group-container {
  display: flex;
  flex-wrap: wrap; /* Permite reorganizar os campos em telas menores */
  gap: 20px; /* Espaço entre os grupos de campos */
  max-width: 100%; /* Largura máxima do formulário */
  margin: 0 auto; /* Centraliza o formulário */
  padding: 10px; /* Espaçamento interno para uma melhor aparência */
}


 .form-div{
   width: 110px;
 }


 /* Classes para campos de entrada personalizados */
 .select-Class, .select-bandeira-Class,.campo-Class{
   width: 50%; /* Ajusta a largura para 100% */
 }

 
 .campo-Str-Class,.campo-Numbercar-Class {
   width: 100%; /* Ajusta a largura para 100% */
 }
 .box-input .form-group {
   flex: 1; /* Permite que os campos se ajustem ao espaço disponível */
   min-width: 10px; /* Largura mínima para manter campos consistentes */
 }


 }
 


</style>