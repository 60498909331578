import { createApp } from 'vue'; // Use createApp para Vue 3
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router'; // Importe createRouter
import HomeView from './views/HomeView.vue';
import FormularioPage from './views/FormularioPage.vue';

const routes = [
  { path: '/', component: HomeView },
  { path: '/donation', component: FormularioPage }
];

const router = createRouter({
  history: createWebHistory(), // Configure o histórico do router
  routes
});



const app = createApp(App); // Crie a instância da aplicação
app.use(router); // Use o router
app.mount('#app'); // Monte a aplicação
