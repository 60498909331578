<template>
    <div class="IssueCrm10">
      
      <h2>Agora informe Seu Endereço:</h2>
      <div class="form-group">
        <div for="cep" class="form-div" >cep  <span class="asterisco">*</span></div>
        <input type="text" id="cep" v-model="FormData.cep"  required class="campo-Class"  @blur="buscaCepApi" />
        
        <span v-if="erro" class="error-message"><br>{{erro }}</span>
      </div>
    
      <div class="form-group">
        <div for="Rua" class="form-div" >Rua  <span class="asterisco">*</span></div>
        <input type="text" id="Rua" v-model="FormData.Rua"  required class="campo-Str-Class" />
      </div>
      <div class="form-group">
        <div for="numbHome" class="form-div" >Numero  <span class="asterisco">*</span></div>
        <input type="text" id="numbHome" v-model="FormData.numbHome"  required class="campo-Class"/>
      </div>
      <div class="form-group">
        <div for="complRua" class="form-div" >Complemento  <span class="asterisco">*</span></div>
        <input type="text" id="complRua" v-model="FormData.complRua"  required class="campo-Str-Class"/>
      </div>
      <div class="form-group">
        <div for="bairro" class="form-div" >Bairro  <span class="asterisco">*</span></div>
        <input type="text" id="bairro" v-model="FormData.bairro"  required class="campo-Str-Class"  />
      </div>
      <div class="form-group">
        <div for="cidade" class="form-div" >Cidade  <span class="asterisco">*</span></div>
        <input type="text" id="cidade" v-model="FormData.cidade"  required class="campo-Str-Class"  />
      </div>
      <div class="form-group">
        <div for="estado"  class="form-div" >Estado<span  class="asterisco">*</span></div>
       <input type="text" id="estado" v-model="FormData.estado"  required class="select-Class"  />
      </div>
      <div class="form-group">
      <div class="checkbox-container">
    <span style="padding-left: 20px;">
    <input type="checkbox" v-model="FormData.receiveUpdates" />
    </span>
    <label>
      Li e aceito as Políticas de Privacidade <span class="asterisco">*</span>?
    </label>
    </div>
</div>


      <div class="form-group">
        <div class="form-div">
        <p>R$ 40,00 único</p>
        
      </div>
      <button class="donate-button">Quero doar!</button>
 </div>
      
    
  
  </div>
  
  </template>
  
  <script>
    import  axios  from "axios";
  export default {
    name: "IssueCrm10",
    version: '1.0.0',
    createdDate: '2024-10-22',
    lastUpdated: '',
    cardStep:0,
    data(){
      return{
          FormData:{
              cep:'',
              Rua:'',
              complRua:'',
              numbHome:'',
              bairro:'',
              cidade:'',
              estado:'',
            
          },
          erro: null
          
      };
    },

    methods: {

      async buscaCepApi(){
        const cep = this.FormData.cep;
        console.log(`Conferir o cep 2: ${cep}`);
        this.erro =cep;
        if(!cep){
          this.erro == "cep errado ! erro 94"
        }
   
//

        try{
          this.erro = null;  // Limpa o erro antes de cada nova busca
          const response = await axios.get(`https://api.doeaqui.org/busca-endereco/${cep}`);
          console.log(`Conferir o cep 2: ${cep}`);
                            
          if(response.data && !response.data.erro){
            this.FormData.Rua    = response.data.logradouro    || "";
            this.FormData.bairro = response.data.bairro        || "";
            this.FormData.cidade = response.data.localidade    || "";
            this.FormData.estado = response.data.uf            || "";
          }else{
            this.erro = "Endereço não encontrado! erro 113";
          }

        }catch(erro){
          
          console.log("Erro ao buscar o endereço: ", erro);
         
          this.erro = `Não foi possível conectar à API. Verifique sua conexão ou tente novamente mais tarde.  erro 117 Descrição: ${erro}`;
        }

//
        },
      },
  };
  
  
  
  
  </script>
  <style scoped>
  /* Importa a fonte Roboto do Google Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
  
  /* Container principal do formulário */
  .form-group-container {
    display: flex;
    flex-wrap: wrap; /* Permite reorganizar os campos em telas menores */
    gap: 20px; /* Espaço entre os grupos de campos */
    max-width: 800px; /* Largura máxima do formulário */
    margin: 0 auto; /* Centraliza o formulário */
    padding: 10px; /* Espaçamento interno para uma melhor aparência */
  }
  
  .form-group {
    display: grid;
    grid-template-columns: auto 2fr; /* Define duas colunas */
    align-items: baseline; /* Alinha verticalmente ao centro */
    margin-left: 15px;
    gap: 15px; /* Espaço entre os itens */
    padding: 15px;
  }
  
  /* Estilo para os rótulos */
  label, h2, .form-div {
    font-family: 'Roboto', sans-serif; /* Fonte desejada */
    font-weight: 300; /* Peso da fonte mais fino */
    margin-bottom: 5px; /* Espaçamento abaixo do rótulo */
  }
  
  .form-group, label {
    font-size: 12px; /* Tamanho de fonte ajustado */
  }
  .form-div{
    width: 150px;
  }
  
  h2 {
    font-size: 16px; /* Tamanho de fonte ajustado */
  }
  
  /* Estilo para os campos de entrada e seleção */
  input,
  select {
    width: 100%; /* Largura total para responsividade */
    padding: 8px; /* Espaço interno */
    font-size: 12px; /* Tamanho de fonte para o texto dentro dos campos */
    border: 1px solid #ccc; /* Borda padrão */
    border-radius: 4px; /* Bordas arredondadas */
    box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
    font-weight: 300; /* Peso da fonte mais leve */
  }
  
  /* Estilo para garantir que os três campos fiquem na mesma linha */
  .box-input {
    display: flex; /* Usa flexbox para alinhar os campos em linha */
    gap: 20px; /* Espaçamento entre os campos */
    flex-wrap: wrap; /* Permite que os campos reorganizem em telas menores */
    margin-bottom: 15px; /* Espaçamento na parte inferior */
  }
  
  .box-input .form-group {
    flex: 1; /* Permite que os campos se ajustem ao espaço disponível */
    min-width: 150px; /* Largura mínima para manter campos consistentes */
  }
  
  .box-input input, 
  .box-input select {
    width: 100%; /* Garante que cada campo ocupe toda a largura disponível dentro do grupo */
    padding: 8px; /* Espaçamento interno */
    border: 1px solid #ccc; /* Borda padrão */
    border-radius: 4px; /* Bordas arredondadas */
    box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
    font-size: 12px; /* Ajuste do tamanho de fonte */
  }
  
  
  input:focus,
  select:focus {
    border-color: #36806D; /* Cor de borda ao focar */
    outline: none; /* Remove o contorno padrão */
  }
  
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  input:focus {
    border-color: #36806D; /* Cor de borda ao focar */
    outline: none; /* Remove o contorno padrão */
  }
  
  .asterisco {
    color: rgba(13, 155, 13, 0.884);
    font-weight: 700;
    padding: 5px;
  }
  
  /* Estilo para checkbox e label */
  .checkbox-container {
    display: flex; /* Usando flexbox para alinhar o checkbox e o label */
    align-items: center; /* Alinha verticalmente ao centro */
    gap: 10px; /* Espaço entre o checkbox e o label */
    margin-bottom: 15px; /* Espaço abaixo do checkbox */
  }
  
  .checkbox-container label {
    font-size: 14px; /* Aumenta o tamanho da fonte do label */
  }
  
  /* Classes para campos de entrada personalizados */
  .select-Class {
    width: 140px; /* Ajusta a largura para 100% */
  }
  .select-bandeira-Class {
    width: 100%; /* Ajusta a largura para 100% */
  }
  .campo-Class {
    width: 140px; /* Ajusta a largura para 100% */
  }
  .campo-Numbercar-Class {
    width: 255px; /* Ajusta a largura para 100% */
  }
  
  .campo-Str-Class {
    width: 255px; /* Ajusta a largura para 100% */
  }

  .donation-info {
  display: flex;
  align-items: center;
}


.donate-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  width: 100px;
}


/* Ajustes para telas menores */
@media (max-width: 600px) {
 
  .form-div{
    width: 90px;
  }


  /* Classes para campos de entrada personalizados */
  .select-Class, .select-bandeira-Class,.campo-Class , .campo-Numbercar-Class {
    width: 50%; /* Ajusta a largura para 100% */
  }
 
  
  .campo-Str-Class {
    width: 100%; /* Ajusta a largura para 100% */
  }
  .box-input .form-group {
    flex: 1; /* Permite que os campos se ajustem ao espaço disponível */
    min-width: 10px; /* Largura mínima para manter campos consistentes */
  }


  }
  

  </style>