<template>
 <div class="header-container">
    <!-- Barra superior com contatos e redes sociais -->
    <div class="top-bar">
      <div class="contact-info">
        
      
      <div class="social-icons">
        Visite nossas redes sociais:
        <a href="https://www.facebook.com/collecttelemarketingsocial"><i class="fab fa-facebook"></i></a>
        <a href="https://www.instagram.com/collecttelemarketingsocial/"> <i class="fab fa-instagram"></i></a>
        
      </div>

    </div>
    </div>

    <!-- Barra inferior com logotipo e navegação -->
    <div class="submenu-bar">
      <div class="logo" >
        <img  :src="textos.ConfiguraLayout.logo.img" :alt="textos.ConfiguraLayout.logo.alt"  />
      </div>
      
    </div>
  </div>

  <div class="donation-form">
    <div class="container">
      <div class="form-container">
        <h1>{{ textos.formulario.titulo }}</h1>
        <div class="donation-selection">
          <IssueCrm7 :currentStep="currentStep" :setDonation="setDonation" />
        <div style="height: 20px;"><br /></div>
          <IssueCrm8 />
          <div style="height: 20px;"><br /></div>
          <Issuecrm9 />
          <Issuecrm10 />
        </div>
      </div>
      <div class="info-box">
        <h2>{{ textos.formulario.infobox.infoboxTitulo }} <button v-if=ativo  class="mas-button" @click="info">+</button> <button v-else class="mas-button" @click="info">-</button></h2>
        <div style="height: 35px; width: 250px;"><br /></div>
        <div v-if=ativo>
        <div v-for="(line, index) in infoboxArray" :key="index">
          <p v-html="line.replace(/\n/g, '<br><br>')"></p>
        </div>
      </div>
      
      </div>
    </div>
  </div>
  <!-- Rodapé -->
  <footer class="footer">
    <div class="footer-content">
      <img :src="textos.ConfiguraLayout.logo.img" :alt="textos.ConfiguraLayout.logo.alt" class="footer-logo">
      <div v-for="(footertexto, index) in footerArray" :key="index" class="footer-details">
        <p v-html="footertexto.replace(/\n/g, '<br>')"></p>
      </div>
    </div>
    <div class="copyright">
      &copy;{{textos.formulario.copyright}}
         </div>
  </footer>
</template>
<script>
import IssueCrm7 from './issuecrm7.vue';
import IssueCrm8 from './issuecrm8.vue';
import Issuecrm9 from './issuecrm9.vue';
import Issuecrm10 from './issuecrm10.vue';
import dados from '@/assets/dados.json';

export default {
 
  name: 'DonationForm',
  version: '1.0.0',
  createdDate: '2024-10-12',
  lastUpdated: '2024-10-14',
  data() {
    return {
      currentStep: 0, // Defina a propriedade currentStep aqui
      selectedDonation: null,
      selectedCard: null,
      textos: dados,
      infoboxArray: [],
      footerArray: [],
       ativo : true,
    };
  },

  created() {
    // Usando split para dividir a string em um array
    //this.infoboxArray = this.textos.formulario.infobox.infoboxtexto.split('|');
    //this.footerArray = this.textos.formulario.footer.split('|');

    // Verificando se infoboxtexto é uma string antes de fazer o split
    if (typeof this.textos.formulario.infobox.infoboxtexto === 'string') {
      this.infoboxArray = this.textos.formulario.infobox.infoboxtexto.split('|');
    } else {
      console.error('infoboxtexto não é uma string:', this.textos.formulario.infobox.infoboxtexto);
    }

    // Verificando se footer é uma string antes de fazer o split
    if (typeof this.textos.formulario.footer === 'string') {
      this.footerArray = this.textos.formulario.footer.split('|');
      console.log('footerArray:', this.footerArray); // Adicione este log para verificar o conteúdo de footerArray
    } else {
      console.error('footer não é uma string:', this.textos.formulario.footer);
    }

  },
  
  components: {

    IssueCrm7,
    IssueCrm8,
    Issuecrm9,
    Issuecrm10
  },
  methods: {
    setDonation(amount) {
      this.selectedDonation = amount;
      this.currentStep = amount; // Atualiza currentStep aqui
    },

    
    setOtherAmount() {
      // Lógica para lidar com um valor diferente
    },
    submitForm() {
      // Lógica para enviar o formulário
      console.log('Formulário enviado com os dados:', this.formData);
      console.log('Valor da doação selecionado:', this.selectedDonation);
    },

    info(){
      
        this.ativo = !this.ativo 
      
    },
   
  },
};


</script>
<style scoped>

/* Estilo para o topo */

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');


.header-container {
  width: 100%;
 
}

.top-bar {
  background-color: #36806D;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  color: #fff;
}

.contact-info span {
  margin-right: 30px;
  margin-top: 300px;
}

.social-icons i {
  margin-left: 15px;
  color: #FFDD57; /* Cor amarela dos ícones */
  gap: 20px;

}



/* Estilo para a barra inferior */
.submenu-bar {
  background-color: #36806D;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: center;
}

  .contact-info,.social-icons {
    flex: 1; /* Permite que a logo ocupe um espaço flexível */
    display: flex; /* Usando flexbox para centralizar */
    justify-content: right; 
}



.logo img {
  height: 150px;

  flex: 1; /* Permite que a logo ocupe um espaço flexível */
  display: flex; /* Usando flexbox para centralizar */
  justify-content: left;
}
/* Importa a fonte Roboto do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

/* Reset CSS para remover margens, preenchimentos e bordas */
label, h2,h1,p{
  font-family: 'Roboto', sans-serif; /* Fonte desejada */
  
  font-weight: 300; /* Peso da fonte mais fino */
  margin-bottom: 5px; /* Espaçamento abaixo do rótulo */
}

.info-box label, p{
  font-size: 12px; /* Tamanho de fonte ajustado */
}
h1{
  font-size: 18px; /* Tamanho de fonte ajustado */

}

h2{
  font-size: 16px; /* Tamanho de fonte ajustado */
}
html,
body {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Evita a barra de rolagem horizontal */
}

.donation-form {
  background-image: url('/src/assets/campanha.jpg'); /* Caminho para a imagem de fundo */
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  padding: 100px;
  gap: 15px; /* Reduzi o espaçamento para telas menores */
  flex-wrap: wrap; /* Permite que os itens se ajustem em telas menores */
  padding-top: 720px;
}

.form-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px; /* Largura máxima para o formulário */
  margin-bottom: 20px; /* Espaço entre os elementos em telas menores */
}

.info-box {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 200px; /* Largura máxima para a caixa de informação */
  margin-bottom: 20px; /* Espaço entre os elementos em telas menores */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Garante que as caixas sejam alinhadas ao topo */
  margin-top: 20px;
  border: none;
  
  gap: 20px; /* Adiciona espaçamento entre os elementos quando estão lado a lado */
}


/* FOOTER */
.footer {
  background-color: #52A28E;
  color: #fff;
  padding: 20px;
  margin-top: 20px; /* Espaço entre o conteúdo principal e o rodapé */
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Permite que o conteúdo se ajuste em telas menores */
  max-width: 120px; /* Define uma largura máxima para centralizar o conteúdo */
  margin: 0 auto; /* Centraliza o conteúdo dentro do footer */
  padding: 20px; /* Espaçamento interno */
}

.footer-logo {
  width: 80px; /* Tamanho do logo ajustado para telas menores */
  margin-right: 20px; /* Espaçamento entre o logo e o conteúdo */
}

.footer-details {
  text-align: left;
  flex: 1; /* Faz com que a seção de detalhes ocupe o espaço disponível */
  margin-bottom: 15px; /* Espaço entre os detalhes do footer em telas menores */
}

.copyright {
  margin-top: 10px; /* Espaçamento superior para separar do restante */
  font-size: 12px; /* Tamanho menor para o texto de copyright em telas menores */
  color: #bbb; /* Cor mais suave para o texto */
}

#app,
.container,
.footer {
  margin: 0;
  padding: 0;
}




/* Media Query para telas menores que 720 pixels */
@media screen and (max-width: 720px) {
  .container {
    flex-direction: column; /* Reorganiza os elementos em uma coluna em telas menores */
    align-items: center; /* Centraliza os elementos na tela */
  }


  


  .donation-form {
    height: auto; /* Altura automática em telas menores */
    padding: 40px 20px; /* Ajusta o padding */
    padding-top: 100px; /* Reduzido para evitar espaços excessivos */
    background-position: center; /* Centraliza a imagem */
    
  }

  .form-container, .info-box {
    width: 80%; /* Ajusta a largura para ocupar a maior parte da tela */
    max-width: 100%; /* Remove a largura máxima */
    margin-left: 0; /* Remove a margem para centralizar os elementos */
    margin-bottom: 20px; /* Espaçamento entre os elementos */
  }

  .form-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px; /* Largura máxima para o formulário */
  margin-bottom: 20px; /* Espaço entre os elementos em telas menores */
}


  .info-box {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%; /* Largura total para manter a fluidez */
    max-width: 400px; /* Largura máxima da caixa */
    margin-bottom: 20px; /* Espaço entre os elementos */
  }

  .footer-content {
    flex-direction: column; /* Muda a direção para coluna em telas pequenas */
    text-align: center; /* Centraliza o texto */
  }

  .footer-logo {
    margin: 0 auto 10px; /* Centraliza o logo e adiciona margem inferior */
  }

  .info-box .mas-button {
    background-color: #440a0a; /* Cor de fundo */
    color: white; /* Texto em branco */
    border: none;
    padding: 4px 4px;
    border-radius: 4px;
    cursor: pointer;
  }
}


</style>
