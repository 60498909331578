<template>
  <div class="IssueCrm8">
  

    <h2>Informe um pouco sobre você:</h2>
    <div class="form-group">
      <div for="name" class="form-div" >Nome  <span class="asterisco">*</span></div>
      <div>
      <input type="text" id="name" v-model="FormData.name" required  class="campo-Str-Class" @blur="validacampo"/>
      <span v-if="FormErrors.name" class="error-message"><br>{{ FormErrors.name }}</span>
    </div>
    </div>
  
    <div class="form-group">
      <div for="email"  class="form-div" >Email<span class="asterisco">*</span></div>
      <div>
        <input type="email" id="email" v-model="FormData.email" required class="campo-Str-Class" @blur="validacampo" />
        <span v-if="FormErrors.email" class="error-message"><br>{{ FormErrors.email }}</span>
      </div>
    </div>
    <div class="form-group">
      <div for="birthdate"  class="form-div" >Data de Nascimento<span  class="asterisco">*</span></div>
      <div>
        <input type="text" id="birthdate" v-model="FormData.birthdate" placeholder="DD/MM/AAAA" required class="campo-Class " @blur="validacampo"  />
        
        <span v-if="FormErrors.birthdate" class="error-message"><br>{{ FormErrors.birthdate }}</span>

      </div>
    </div>

    <div class="form-group">
      <div for="phone"  class="form-div" >Número de Telefone<span  class="asterisco">*</span></div>
      <div>
        <input type="text" id="phone" v-model="FormData.phone" placeholder="(__) ____-____" required  class="campo-Class "  @blur="validacampo"/>
        <span v-if="FormErrors.phone" class="error-message"><br>{{ FormErrors.phone }}</span>
      </div>
    </div>
    
    <div class="form-group">
      <div for="gender"  class="form-div" >Sexo<span  class="asterisco">*</span></div>
      <div>
      <select id="gender" v-model="FormData.gender" required class="select-Class"  @blur="validacampo"> 
        <option value="" disabled selected>Selecione</option>
        <option value="male">Masculino</option>
        <option value="female">Feminino</option>
        <option value="other">Outro</option>
      </select>
      <span v-if="FormErrors.gender" class="error-message"><br>{{ FormErrors.gender }}</span>
    </div>

    </div>


  <div class="checkbox-container">
    <span style="padding-left: 20px;">
    <input type="checkbox" v-model="FormData.receiveUpdates" />
  </span>
    <label>
      Deseja receber informações sobre o impacto das doações recebidas e demais campanhas?
    </label>
  </div>
</div>

</template>
<script>

import IssueCrm8dados from '@/assets/dados.json';


export default {
  name: "IssueCrm8",
  version: '1.0.0',
  createdDate: '2024-10-13',
  lastUpdated: '2024-10-15',


  data() {
    return {
      IssueCrm8textos: IssueCrm8dados, // Assumindo que você carrega o JSON como um objeto de dados chamado 'dados',
      selectedDonation: null,
      FormData: {
        name: '',
        email:'',
        birthdate:'',
        phone:'',
        gender:'',
        receiveUpdates:''
       
      },
      FormErrors:{
        name: '',
        email:'',
        birthdate:'',
        phone:'',
        gender:''
      },
    };

  },

  methods:{

    validacampo(){
      this.FormErrors.name =  this.FormData.name.trim() === '' ?"Campo não pode estar vazio":''; 
      this.FormErrors.email =  this.FormData.email.trim() === '' ?"Campo não pode estar vazio"
           :(!this.validaemail(this.FormData.email))?"Email invalido":''; 
      this.FormErrors.birthdate = this.FormData.birthdate.trim()===''?"Campo não pode estar vazio"
           :(!this.validardata(this.FormData.birthdate))?"Data invalida exemplo(00/00/0000)":""  
      this.FormErrors.phone = this.FormData.phone.trim()===''?"Campo não pode estar vazio"
           :(!this.validarphone(this.FormData.phone))?"Telefone invalida":""
      this.FormErrors.gender = this.FormData.gender.trim() === '' ? "O campo Gênero não pode estar vazio." : '';                    
     
    },
    validaemail: (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
    validardata: (birthdate)=> /^\d{2}\/\d{2}\/\d{4}$/.test(birthdate),
    validarphone: (phone) => /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/.test(phone),



  }

}


</script>


<style scoped>
/* Importa a fonte Roboto do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

/* Container principal do formulário */
.form-group-container {
  display: flex;
  flex-wrap: wrap; /* Permite reorganizar os campos em telas menores */
  gap: 20px; /* Espaço entre os grupos de campos */
  max-width: 800px; /* Largura máxima do formulário */
  margin: 0 auto; /* Centraliza o formulário */
  padding: 10px; /* Espaçamento interno para uma melhor aparência */
}

.form-group {
  display: grid;
  grid-template-columns: auto 2fr; /* Define duas colunas */
  align-items: baseline; /* Alinha verticalmente ao centro */
  margin-left: 15px;
  gap: 15px; /* Espaço entre os itens */
  padding: 15px;
}

/* Estilo para os rótulos */
label, h2, .form-div {
  font-family: 'Roboto', sans-serif; /* Fonte desejada */
  font-weight: 300; /* Peso da fonte mais fino */
  margin-bottom: 5px; /* Espaçamento abaixo do rótulo */
}

.form-group, label {
  font-size: 12px; /* Tamanho de fonte ajustado */
}
.form-div{
  width: 150px;
}

h2 {
  font-size: 16px; /* Tamanho de fonte ajustado */
}

/* Estilo para os campos de entrada e seleção */
input,
select {
  width: 100%; /* Largura total para responsividade */
  padding: 8px; /* Espaço interno */
  font-size: 12px; /* Tamanho de fonte para o texto dentro dos campos */
  border: 1px solid #ccc; /* Borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
  font-weight: 300; /* Peso da fonte mais leve */
}

/* Estilo para garantir que os três campos fiquem na mesma linha */
.box-input {
  display: flex; /* Usa flexbox para alinhar os campos em linha */
  gap: 20px; /* Espaçamento entre os campos */
  flex-wrap: wrap; /* Permite que os campos reorganizem em telas menores */
  margin-bottom: 15px; /* Espaçamento na parte inferior */
}

.box-input .form-group {
  flex: 1; /* Permite que os campos se ajustem ao espaço disponível */
  min-width: 150px; /* Largura mínima para manter campos consistentes */
}

.box-input input, 
.box-input select {
  width: 100%; /* Garante que cada campo ocupe toda a largura disponível dentro do grupo */
  padding: 8px; /* Espaçamento interno */
  border: 1px solid #ccc; /* Borda padrão */
  border-radius: 4px; /* Bordas arredondadas */
  box-sizing: border-box; /* Inclui padding e bordas no tamanho total */
  font-size: 12px; /* Ajuste do tamanho de fonte */
}


input:focus,
select:focus {
  border-color: #36806D; /* Cor de borda ao focar */
  outline: none; /* Remove o contorno padrão */
}


.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

input:focus {
  border-color: #36806D; /* Cor de borda ao focar */
  outline: none; /* Remove o contorno padrão */
}

.asterisco {
  color: rgba(13, 155, 13, 0.884);
  font-weight: 700;
  padding: 5px;
}

/* Estilo para checkbox e label */
.checkbox-container {
  display: flex; /* Usando flexbox para alinhar o checkbox e o label */
  align-items: center; /* Alinha verticalmente ao centro */
  gap: 10px; /* Espaço entre o checkbox e o label */
  margin-bottom: 15px; /* Espaço abaixo do checkbox */
}

.checkbox-container label {
  font-size: 14px; /* Aumenta o tamanho da fonte do label */
}


/* Classes para campos de entrada personalizados */
.select-Class {
  width: 30%; /* Ajusta a largura para 100% */
}

.campo-Class {
  width: 30%; /* Ajusta a largura para 100% */
}

.campo-Str-Class {
  width: 100%; /* Ajusta a largura para 100% */
}


/* Ajustes para telas menores */
@media (max-width: 600px) {
 
 .form-div{
   width: 90px;
 }


 /* Classes para campos de entrada personalizados */
 .select-Class, .select-bandeira-Class,.campo-Class , .campo-Numbercar-Class {
   width: 50%; /* Ajusta a largura para 100% */
 }

 
 .campo-Str-Class {
   width: 100%; /* Ajusta a largura para 100% */
 }
 .box-input .form-group {
   flex: 1; /* Permite que os campos se ajustem ao espaço disponível */
   min-width: 10px; /* Largura mínima para manter campos consistentes */
 }


 }
 


</style>
